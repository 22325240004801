import React from 'react'
import PropTypes from 'prop-types'
import {kebabCase} from 'lodash'
import {Helmet} from 'react-helmet'
import {graphql, Link} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import {GatsbyImage} from "gatsby-plugin-image";

export const RegelnBlogPostTemplate = ({
                                         content,
                                         contentComponent,
                                         frontmatter,
                                         helmet,
                                       }) => {
  const PostContent = contentComponent || Content
  const imageStyle = {width: '120px', margin: "auto"}

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {frontmatter.title}
            </h1>
            <p>{frontmatter.description}</p>
            <PostContent content={content}/>
            {frontmatter.amazonlink === null ?
              null : (
                <div>
                  <div className="columns is-centered ">
                    <div className="column is-4" style={{marginTop: `4rem`}}>
                      <a href={frontmatter.amazonlink} rel="nofollow">
                        <GatsbyImage style={imageStyle} image={frontmatter.productimage.childImageSharp.gatsbyImageData}/>
                      </a>
                      <br />

                        <div style={{marginTop: `1rem`, textAlign: 'center'}}>
                          <a className="btn" href={frontmatter.amazonlink} rel="nofollow">Auf Amazon ansehen*</a>
                        </div>
                    </div>
                    {frontmatter.iframeLink ? (
                      <div className="column is-4 mt-6">
                        <iframe style={{"width": 120, "height": 240}} marginWidth="0" marginHeight="0"
                                scrolling="no" frameBorder="0" src={frontmatter.iframeLink}/>
                      </div>
                    ) : null}
                  </div>

                </div>
              )}

            <div className="pt-6">
              <h4 className="title is-3">Ähnliche Spiele</h4>
              <ul>
            {
              frontmatter.similarGames?.map((data)=>{
                return (<li key={data.name}><a className="btn" href={data.link}>{data.name}*</a></li>)
              })
            }
              </ul>

            </div>

            {frontmatter.tags && frontmatter.tags.length ? (
              <div style={{marginTop: `4rem`}}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {frontmatter.tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

RegelnBlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  frontmatter: PropTypes.object,
  helmet: PropTypes.object,
}

const RegelnBlogPost = ({data}) => {
  const {markdownRemark: post} = data

  return (
    <Layout>
      <RegelnBlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        frontmatter={post.frontmatter}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  )
}

RegelnBlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default RegelnBlogPost

export const pageQuery = graphql`
  query RegelnBlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        amazonlink
        productimage {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 120, quality: 70)
          }
        }
        iframeLink
        tags
        similarGames{
          name
          link
        }
      }
    }
  }
`
